import {
    FormGroup,
    MoreInfo,
    TextInput,
} from '@genomicsplc/denim-components';
import {
    useEffect,
    useMemo,
    useRef,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import {
    useLocation,
    useNavigate,
    useOutletContext,
} from 'react-router-dom';
import {FetchValidationError} from '../../../fetch-validation-error';
import {StateNotEligible} from './StateNotEligible';

export function HomeAddress() {
    const intl = useIntl();
    const {pathname, state} = useLocation();
    const {
        currentUserProfile: {homeAddress} = {},
        errorResponse,
        setPersistFields,
    } = useOutletContext();
    const navigate = useNavigate();
    const apartmentSuiteBuildingRef = useRef();
    const cityRef = useRef();
    const stateRef = useRef();
    const streetAddressRef = useRef();
    const zipCodeRef = useRef();

    useEffect(() => {
        setPersistFields(() => () => ({
            homeAddress: {
                apartmentSuiteBuilding: apartmentSuiteBuildingRef.current?.value ?? '',
                city: cityRef.current?.value,
                state: stateRef.current?.value,
                streetAddress: streetAddressRef.current?.value,
                zipCode: zipCodeRef.current?.value,
            },
        }));

        return () => {
            setPersistFields(null);
        };
    }, []);

    useEffect(() => {
        if (errorResponse?.errorData?.errors?.includes('ADDRESS_STATE_NOT_SUPPORTED')) {
            navigate(pathname, {state: {userIneligible: true}});
        }
    }, [errorResponse]);

    const displayIneligiblePage = useMemo(() => state?.userIneligible ?? false, [state]);
    if (displayIneligiblePage) {
        return <StateNotEligible />;
    }

    return (
        <>
            <h2
                className={'title'}
                data-test-id={'sign-up-home-address-title'}
            >
                <FormattedMessage id={'signUp.homeAddress.title'} />
            </h2>
            <MoreInfo
                content={intl.formatMessage({id: 'signUp.homeAddress.moreInfo'})}
                title={intl.formatMessage({id: 'common.whyWeAskThisQuestion'})}
            />
            <FetchValidationError
                errorResponse={streetAddressRef.current?.value ? errorResponse : null}
                pathname={pathname}
            />
            <FormGroup
                dataTestId={'street-address-form-group'}
                label={intl.formatMessage({id: 'signUp.homeAddress.field.street.label'})}
                labelFor={'street-address'}
            >
                <TextInput
                    autoComplete={'address-line1'}
                    dataTestId={'street-address-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'street-address'}
                    name={'streetAddress'}
                    ref={streetAddressRef}
                    required={true}
                    value={homeAddress?.streetAddress ?? ''}
                />
            </FormGroup>
            <FormGroup
                dataTestId={'apartment-suite-building-form-group'}
                label={intl.formatMessage({id: 'signUp.homeAddress.field.apartment.label'})}
                labelFor={'apartment-suite-building'}
            >
                <TextInput
                    autoComplete={'address-line2'}
                    dataTestId={'apartment-suite-building-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'apartment-suite-building'}
                    name={'apartmentSuiteBuilding'}
                    ref={apartmentSuiteBuildingRef}
                    value={homeAddress?.apartmentSuiteBuilding ?? ''}
                />
            </FormGroup>
            <FormGroup
                dataTestId={'city-form-group'}
                label={intl.formatMessage({id: 'signUp.homeAddress.field.city.label'})}
                labelFor={'city'}
            >
                <TextInput
                    autoComplete={'address-level2'}
                    dataTestId={'city-input'}
                    errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                    id={'city'}
                    name={'city'}
                    ref={cityRef}
                    required={true}
                    value={homeAddress?.city ?? ''}
                />
            </FormGroup>
            <div className={'inline-form-groups'}>
                <FormGroup
                    dataTestId={'state-form-group'}
                    label={intl.formatMessage({id: 'signUp.homeAddress.field.state.label'})}
                    labelFor={'state'}
                >
                    <TextInput
                        autoComplete={'address-level1'}
                        dataTestId={'state-input'}
                        errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                        id={'state'}
                        name={'state'}
                        ref={stateRef}
                        required={true}
                        value={homeAddress?.state.trim() ?? ''}
                    />
                </FormGroup>
                <FormGroup
                    dataTestId={'zip-code-form-group'}
                    label={intl.formatMessage({id: 'signUp.homeAddress.field.zipCode.label'})}
                    labelFor={'zip-code'}
                >
                    <TextInput
                        autoComplete={'postal-code'}
                        dataTestId={'zip-code-input'}
                        errorMessages={{valueMissing: intl.formatMessage({id: 'forms.common.error.required'})}}
                        id={'zip-code'}
                        name={'zipCode'}
                        ref={zipCodeRef}
                        required={true}
                        value={homeAddress?.zipCode ?? ''}
                    />
                </FormGroup>
            </div>
        </>
    );
}
